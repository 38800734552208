<template>
<section>
    <div style="padding: 0 1em 1em 1em;">
        <div class="row">
            <div class="col-12">
                <span style="font-weight:bolder;">เงื่อนไขการเปลี่ยน – คืนสินค้า</span>
            </div>
            <div class="col-12">
                <span style="font-weight:bolder;">1.</span> ลูกค้าต้องตรวจรับสินค้า และปฏิเสธการรับสินค้านั้นทันที หากพบปัญหาการจัดส่งสินค้าที่ผิดไปจากที่สั่งซื้อ เช่น ผิดรุ่น ผิดสีจำนวนไม่ครบ หรือสินค้าชำรุดเสียหาย เป็นต้น
            </div>
            <div class="col-12">
                <span style="font-weight:bolder;">2.</span> บริษัทยินดีรับเปลี่ยน/คืนสินค้าภายใน 30 วัน โดยนับจากวันที่ชำระเงิน จนถึงวันที่นำส่งคืนสินค้า และสินค้าต้องอยู่ในสภาพสมบูรณ์ <span style="font-weight:bolder;">100%</span>
            </div>
            <div class="col-12">
                <span style="font-weight:bolder;">3.</span> ไม่รับเปลี่ยน-คืน กรณีที่สินค้าถูกใช้งานแล้ว และลูกค้าต้องการเปลี่ยนใจโดยไม่ใช่สาเหตุจากปัญหาผลิตภัณฑ์
            </div>
        </div>
        <table class="table text-center">
          <tbody>
            <tr style="background-color: #55246c;color:white;font-weight:bolder">
              <td rowspan="2">สาเหตุการคืน</td>
              <td colspan="5">สินค้าที่คืน/เปลี่ยนต้องอยู่ในสภาพ</td>
            </tr>
            <tr style="background-color: rgb(184, 41, 47,0.5);font-weight:bolder">
              <td>บรรจุภัณฑ์ยังไม่ถูกแกะ</td>
              <td>อุปกรณ์ครบสมบูรณ์ตามมาตรฐาน</td>
              <td>สินค้ายังไม่ถูกใช้งาน</td>
              <td>มีใบเสร็จรับเงิน/ใบกำกับภาษี</td>
              <td>ระยะเวลาการคืนสินค้า</td>
            </tr>
            <tr style="background-color: rgb(184, 41, 47,0.3);font-weight:bolder">
              <td>สินค้าที่ผิดไปจากที่สั่งซื้อ เช่น ผิดรุ่น ผิดสี จำนวนไม่ครบ หรือสินค้าชำรุดเสียหาย</td>
              <td><i class="fa fa-minus" aria-hidden="true"></i></td>
              <td><i class="fa fa-check" aria-hidden="true"></i></td>
              <td><i class="fa fa-check" aria-hidden="true"></i></td>
              <td><i class="fa fa-check" aria-hidden="true"></i></td>
              <td rowspan="4">ภายใน 30 วันนับจากวันที่ได้รับสินค้า</td>
            </tr>
            <tr style="background-color: rgb(184, 41, 47,0.5);font-weight:bolder">
              <td>สินค้าที่มีปัญหาจากตัวผลิตภัณฑ์หรือการติดตั้งโดยบริษัทฯ</td>
              <td><i class="fa fa-minus" aria-hidden="true"></i></td>
              <td><i class="fa fa-check" aria-hidden="true"></i></td>
              <td><i class="fa fa-minus" aria-hidden="true"></i></td>
              <td><i class="fa fa-check" aria-hidden="true"></i></td>
            </tr>
            <tr style="background-color: rgb(184, 41, 47,0.3);font-weight:bolder">
              <td>สินค้าเสียหายจากการขนส่ง</td>
              <td><i class="fa fa-minus" aria-hidden="true"></i></td>
              <td><i class="fa fa-check" aria-hidden="true"></i></td>
              <td><i class="fa fa-check" aria-hidden="true"></i></td>
              <td><i class="fa fa-check" aria-hidden="true"></i></td>
            </tr>
            <tr style="background-color: rgb(184, 41, 47,0.5);font-weight:bolder">
              <td>เปลี่ยนใจได้ไม่ว่ากัน*</td>
              <td><i class="fa fa-check" aria-hidden="true"></i></td>
              <td><i class="fa fa-check" aria-hidden="true"></i></td>
              <td><i class="fa fa-check" aria-hidden="true"></i></td>
              <td><i class="fa fa-check" aria-hidden="true"></i></td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-12">
            <span style="font-weight:bolder;">ขอสงวนสิทธิ์ในการไม่รับคืนสินค้าประเภทต่อไปนี้</span>
          </div>
          <div class="col-12">
            <span style="font-weight:bolder;">1.</span> กลุ่มสินค้าภาพและเสียง เช่น โทรทัศน์, เครื่องเสียง, เครื่องเล่น CD, DVD, Blu-ray เป็นต้น
          </div>
          <div class="col-12">
            <span style="font-weight:bolder;">2.</span> กลุ่มสินค้าไอที คอมพิวเตอร์ และอิเล็คโทรนิค เช่น คอมพิวเตอร์ โทรศัพท์มือถือ กล้องถ่ายรูป เป็นต้น
          </div>
          <div class="col-12">
            <span style="font-weight:bolder;">3.</span> กลุ่มสินค้าสั่งพิเศษ/สั่งผลิต  เช่น สีผสม ผ้าม่านสั่งตัด ท่อสั่งตัด เป็นต้น
          </div>
          <div class="col-12">
            <span style="font-weight:bolder;">4.</span> กลุ่มสินค้า Clearance ราคาพิเศษ (ลงท้ายด้วยเลข 4)
          </div>
          <div class="col-12">
            <span style="font-weight:bolder;">5.</span> สินค้าที่มีระยะเวลาจำกัดในการใช้งาน เช่น ถ่านไฟฉาย เคมีภัณฑ์ อาหารและเครื่องดื่ม เป็นต้น
          </div>
          <div class="col-12">
            <span style="font-size:.8em;">
              *การเปลี่ยนคืนสินค้าเป็นไปตามเงื่อนไขที่บริษัทกำหนดและไม่รับเปลี่ยน/คืนกรณีเปลี่ยนใจในกลุ่มสินค้าที่สงวนสิทธิ์ข้างต้น ลูกค้าจะต้องรับผิดชอบค่าใช้จ่ายในการขนส่งทั้งหมดหากต้องการเปลี่ยน-คืนสินค้า
            </span>
          </div>
          <div class="col-12">
            <span style="font-weight:bolder;">ช่องทางการเปลี่ยน-คืนสินค้า</span>
          </div>
          <div class="col-12">
            หากพบปัญหาสามารถติดต่อฝ่ายบริการลูกค้าได้ที่อีเมลล์ <a href="mailto:contactcenter@thaiwatsadu.com">contactcenter@thaiwatsadu.com</a> หรือ Call Center 1308 ตั้งแต่เวลา 07.00 - 20.00 ของทุกวัน
          </div>
          <div class="col-12">
            <span style="font-weight:bolder;">จุดรับเปลี่ยน - คืนสินค้า: </span>ไทวัสดุทุกสาขา
          </div>
          <div class="col-12">
            <span style="font-weight:bolder;">เงื่อนไขการจ่ายคืนค่าสินค้าและบริการ</span>
          </div>
          <div class="col-12">
            บริษัทฯ ขอสงวนสิทธิ์ในการพิจารณาคืนค่าสินค้าและบริการ โดยจ่ายคืนตามประเภทของการชำระเงินเมื่อทางบริษัทได้รับสินค้าจากลูกค้าแล้วเท่านั้น ซึ่งจะดำเนินการคืนค่าสินค้าโดยวิธีการดังต่อไปนี้ :
          </div>
          <div class="col-1" style="width:5%;"></div>
          <div class="col-11">
            •	จ่ายคืนเข้าบัญชีธนาคารของลูกค้า ภายใน <span style="font-weight:bolder;">7-14</span> วันหลังจากบริษัทฯยืนยันการรับคืน
          </div>
          <div class="col-1" style="width:5%;"></div>
          <div class="col-11">
            •	จ่ายคืนวงเงินเข้าบัตรเครดิตของลูกค้า ภายใน <span style="font-weight:bolder;">14</span> วันหลังจากบริษัทฯยืนยันการรับคืนและจะมีผลในรอบบัญชีถัดไปของบัตรเครดิต
          </div>
          <div class="col-1" style="width:5%;"></div>
          <div class="col-11">
            •	กรณีลูกค้าชำระผ่านบัตรเดบิต ลูกค้าจะได้รับเงินคืนภายใน <span style="font-weight:bolder;">45</span> วันทำการ
          </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: "FooterRefundPolicy"
}
</script>

<style>
table,
th,
td {
    border: solid white;
    vertical-align: middle !important;
}
</style>
