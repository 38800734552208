<template>
  <section>
      <FooterRefundPolicy/>
  </section>
</template>

<script>
import FooterRefundPolicy from '@/components/Footer/FooterRefundPolicy.vue';
export default {
    name: "RefundPolicy",
    components: {
      FooterRefundPolicy
    },
    mounted() {
      document.title = `${process.env.VUE_APP_TITLE} - Refund Policy`
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
      document.head.appendChild(recaptchaScript)
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-CRY3HG283C');
    }
}
</script>

<style>

</style>